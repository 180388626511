import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Card(props) {

    const navigateTo = useNavigate()
    const [data, setData] = useState(null)
    const [loadingClass, setLoadingClass] = useState("btn btn-primary px-3 py-2 mb-3")

    useEffect(() => {
        if (props.loading) {
            setLoadingClass("btn btn-outline-primary p-0 m-0 border-0")
            //show spinner
            setData(<h4>
                <div className="spinner-border mt-2" role="status">
                    <span className="visually-hidden">Generando datos...</span>
                </div>
            </h4>)
        } else {
            //hide spinner
            setLoadingClass("btn btn-primary px-3 py-2 mb-3")
            setData(false)
        }
    }, [props])


    return (
        <div className="card rounded no-shadow py-3">
            <div className="card-body">
                {props.icon}
                <h4 className="h5 fw-medium my-4">
                    {props.title}
                </h4>
                <button className={loadingClass} type="button" disabled={props.loading} onClick={
                    () => {
                        props.isCallback ? props.loading ? <>{data}</> : props.callback() : navigateTo(props.route)
                    }
                }>
                    {props.loading ? <>{data}</> : props.buttonValue ?? 'Ver más'}
                </button>
            </div>
        </div>
    )
}
