import React, { useState, useEffect, Fragment } from 'react'
import TooltipComponent from './TooltipComponent'

export default function ListSection(props) {

    const {
        aboveTitle,
        belowTitle,
        title,
        hasSearch,
        hasYear,
        tabs,
        backRoute,
        actions,
        columns,
        rows,
        pagination,
        searchText = 'Buscar...',
    } = props

    const [tabsState, setTabsState] = useState(null)
    const [renderList, setRenderList] = useState(null)
    const [search, setSearch] = useState('')

    const [paginationState, setPaginationState] = useState(pagination || null)

    const handleFilterAndRender = (filters) => {
        let allData = rows
        if (filters && Array.isArray(filters)) {
            for (let filter of filters) {
                allData = allData.map(
                    (row, index) => {
                        if (row[filter.criteria] === filter.value) {
                            row.hidden = false
                        } else {
                            row.hidden = true
                        }
                        return row
                    }
                )
            }

            let filteredList = allData.filter(row => !row.hidden)

            if (search !== '') {
                filteredList = filteredList.filter(row => {
                    let found = false
                    for (let column of columns) {
                        if (row[column.field].toString().toLowerCase().includes(search.toString().toLowerCase())) {
                            found = true
                            break
                        }
                    }
                    return found
                })
            }

            if (paginationState) {
                let pages = []
                let maxPagesInPagination = paginationState.maxPagesInPagination
                let currentPage = paginationState.currentPage
                let totalPages = Math.ceil(filteredList.length / paginationState.itemsPerPage)
                let startPage = 1
                let endPage = totalPages
                if (totalPages > maxPagesInPagination) {
                    let maxPagesBeforeCurrentPage = Math.floor(maxPagesInPagination / 2)
                    let maxPagesAfterCurrentPage = Math.ceil(maxPagesInPagination / 2) - 1
                    if (currentPage <= maxPagesBeforeCurrentPage) {
                        //current page near the start
                        endPage = maxPagesInPagination
                    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                        //current page near the end
                        startPage = totalPages - maxPagesInPagination + 1
                    } else {
                        //current page somewhere in the middle
                        startPage = currentPage - maxPagesBeforeCurrentPage
                        endPage = currentPage + maxPagesAfterCurrentPage
                    }
                }
                for (let i = startPage; i <= endPage; i++) {
                    pages.push(i)
                }

                setPaginationState({
                    ...paginationState,
                    currentPage: paginationState.currentPage,
                    totalPages: Math.ceil(filteredList.length / paginationState.itemsPerPage),
                    pages: pages,
                })

                filteredList = filteredList.slice(
                    (paginationState.currentPage - 1) * paginationState.itemsPerPage,
                    paginationState.currentPage * paginationState.itemsPerPage
                )
            }

            setRenderList(
                filteredList
            )
        }
    }

    const handleTabStateChange = (index, filters = null) => {
        setTabsState(
            tabsState?.map((tab, i) => {
                if (i === index) {
                    tab.active = true
                } else {
                    tab.active = false
                }
                return tab
            }) || []
        )
        handleFilterAndRender(filters)
    }

    const copyTextToClipboard = (text) => {
        navigator.clipboard.writeText(text)
    }

    useEffect(() => {
        if (tabs !== null && tabs.length > 0) {
            let currentTab = tabs.find(tab => tab.active)
            if (!currentTab) {
                let newTabs = tabs.map(tab => {
                    tab.active = false
                    return tab
                })
                newTabs[0].active = true
                currentTab = newTabs[0]
                setTabsState(newTabs)
            } else {
                setTabsState(tabs)
            }
            handleFilterAndRender(currentTab.filter)
        } else if (tabs == false) {
            let filteredList;
            if (search !== '') {
                filteredList = rows.filter(row => {
                    let found = false
                    for (let column of columns) {
                        if (row[column.field].toString().toLowerCase().includes(search.toString().toLowerCase())) {
                            found = true
                            break
                        }
                    }
                    return found
                })
            } else {
                filteredList = [...rows];
            }

            if (paginationState) {

                let pages = []
                let maxPagesInPagination = paginationState.maxPagesInPagination
                let currentPage = paginationState.currentPage
                let totalPages = Math.ceil(filteredList.length / paginationState.itemsPerPage)
                let startPage = 1
                let endPage = totalPages
                if (totalPages > maxPagesInPagination) {
                    let maxPagesBeforeCurrentPage = Math.floor(maxPagesInPagination / 2)
                    let maxPagesAfterCurrentPage = Math.ceil(maxPagesInPagination / 2) - 1
                    if (currentPage <= maxPagesBeforeCurrentPage) {
                        //current page near the start
                        endPage = maxPagesInPagination
                    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                        //current page near the end
                        startPage = totalPages - maxPagesInPagination + 1
                    } else {
                        //current page somewhere in the middle
                        startPage = currentPage - maxPagesBeforeCurrentPage
                        endPage = currentPage + maxPagesAfterCurrentPage
                    }
                }
                for (let i = startPage; i <= endPage; i++) {
                    pages.push(i)
                }

                setPaginationState({
                    ...paginationState,
                    currentPage: paginationState.currentPage,
                    totalPages: Math.ceil(filteredList.length / paginationState.itemsPerPage),
                    pages: pages,
                })

                filteredList = filteredList.slice(
                    (paginationState.currentPage - 1) * paginationState.itemsPerPage,
                    paginationState.currentPage * paginationState.itemsPerPage
                )

            }

            setRenderList(filteredList)
        }
        return () => {
            setTabsState(null)
            setRenderList(null)
        }
    }, [tabs, rows, search, paginationState?.currentPage])

    return (
        <main className="theme-light">
            {
                aboveTitle
            }
            <div className="container">
                <div className="row mt-md-0 mt-sm-5">
                    <div className="col-lg-8 col-12 py-lg-0 py-2 d-flex align-items-center justify-content-between">
                        <div className='d-flex justify-content-start align-item-center'>
                            {
                                backRoute?.title &&
                                <div className="btn-group me-3">
                                    <button className="btn btn-outline-primary px-3 py-2" type="button" onClick={backRoute.onClick}>
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                                </div>
                            }
                            <h3 className="page-title fw-bold mb-lg-0 mb-0">
                                {title}
                            </h3>
                        </div>
                    </div>
                    {
                        (hasYear || hasSearch) && <>
                            <div className="col-lg-4 col-12 py-lg-0 py-2 d-flex justify-content-end">
                                {
                                    hasSearch && <>
                                        <div className="input-group search-form">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={searchText}
                                                aria-label="Buscar"
                                                aria-describedby="basic-addon2"
                                                value={search}
                                                onChange={
                                                    (e) => setSearch(e.target.value)
                                                }
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    hasYear && <>
                                        <div className="btn-group">
                                            <button className="btn btn-outline-primary btn-lg dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                2023
                                            </button>
                                            <div className="dropdown-menu">
                                                ...
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                <div className="row mb-4 d-flex align-items-center justify-content-between">
                    {
                        belowTitle
                    }
                </div>
                <div className="row d-flex align-items-center justify-content-between">
                    {
                        tabsState?.length > 0 ?
                            <>
                                <div className="col-md-7 d-flex justify-content-start">
                                    <ul className="nav nav-tabs">
                                        {
                                            tabsState?.map((tab, index) => {
                                                return (
                                                    <li className="nav-item" key={index}>
                                                        <a
                                                            className={`nav-link react-link ${tab.active ? 'active' : ''}`}
                                                            onClick={() => {
                                                                handleTabStateChange(index, tab.filter)
                                                            }}
                                                        >
                                                            {tab.title}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </> :
                            <div className="col-md-7">
                            </div>
                    }
                    {
                        actions?.length > 0 &&
                        (
                            <div className="col-md-5 d-flex justify-content-end">
                                {
                                    actions.map((action, index) => {
                                        return (action?.title) && <Fragment key={index}>
                                            {
                                                action?.title && <div className="btn-group mt-lg-0 mt-4">
                                                    <button className={`btn px-3 py-2 ${action?.className || 'btn-primary '} ${index >= 1 && 'ms-2'}`} type="button" onClick={action.onClick}>
                                                        {action.title}
                                                    </button>
                                                </div>
                                            }
                                        </Fragment>
                                    })
                                }
                            </div>
                        )
                    }
                    {
                        typeof actions === 'object' &&
                        (
                            (actions?.title) && <>
                                <div className="col-md-5 d-flex justify-content-end">
                                    {
                                        actions?.title && <div className="btn-group mt-lg-0 mt-4">
                                            <button className={`btn px-3 py-2 ${actions?.className || 'btn-primary '}`} type="button" onClick={actions.onClick}>
                                                {actions.title}
                                            </button>
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="card bg-fondo-suave">
                            <div className="card-body">
                                {
                                    renderList?.length === 0 && <div className="alert alert-info" role="alert">
                                        No hay registros aún.
                                    </div>
                                }
                                {
                                    renderList?.length > 0 &&
                                    <div className='table-responsive'>
                                        <table className="table">
                                            <thead className="table-light">
                                                <tr>
                                                    {
                                                        columns.map((column, index) => {
                                                            return (
                                                                <th key={index}>{column.title}</th>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    renderList.map((row, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                {
                                                                    columns.map((column, index2) => {
                                                                        return (
                                                                            <td
                                                                                key={index2}
                                                                                className={row[column.field]?.className || 'align-middle'}
                                                                            >
                                                                                {
                                                                                    column.type === 'text' && (
                                                                                        column.canBeCopied ? (
                                                                                            typeof row[column.field] === 'string' ?
                                                                                                <a onClick={
                                                                                                    () => copyTextToClipboard(row[column.field])
                                                                                                }>{row[column.field]}</a> :
                                                                                                <a onClick={
                                                                                                    () => copyTextToClipboard(row[column.field]?.text)
                                                                                                }>{row[column.field]?.text}</a>
                                                                                        ) : (
                                                                                            typeof row[column.field] === 'string' ?
                                                                                                row[column.field] :
                                                                                                row[column.field]?.text
                                                                                        )
                                                                                    )
                                                                                }
                                                                                {
                                                                                    column.type === 'link' && (
                                                                                        typeof row[column.field] === 'string' ?
                                                                                            <a href={row[column.field]}>{row[column.field]}</a> :
                                                                                            <a href={row[column.field]?.text}>{row[column.field?.text]}</a>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    column.type === 'date' && (
                                                                                        typeof row[column.field] === 'string' ?
                                                                                            row[column.field] :
                                                                                            row[column.field]?.text
                                                                                    )
                                                                                }
                                                                                {
                                                                                    column.type === 'numeric' && (
                                                                                        typeof row[column.field] === 'string' ?
                                                                                            row[column.field] :
                                                                                            row[column.field]?.text
                                                                                    )
                                                                                }
                                                                                {
                                                                                    column.type === 'actions' && <>
                                                                                        <div className="btn-group">
                                                                                            {
                                                                                                row[column.field].map((action, index3) => {

                                                                                                    return (
                                                                                                        <TooltipComponent
                                                                                                            key={index3}
                                                                                                            title={action.tooltip}
                                                                                                        >
                                                                                                            <button
                                                                                                                className={action.className}
                                                                                                                onClick={action.onClick}
                                                                                                            >
                                                                                                                <i className={action.icon}></i>
                                                                                                            </button>
                                                                                                        </TooltipComponent>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    pagination && <>
                        <div className="row py-4">
                            <div className="col-12 d-flex justify-content-end">
                                <nav aria-label="Navegación">
                                    <ul className="pagination">
                                        {
                                            paginationState?.totalPages > 1 && <>
                                                <li className={`page-item`}>
                                                    <a
                                                        className={`page-link  ${paginationState.currentPage !== 1 ? 'clickable react-link' : ''}`}
                                                        onClick={() => {
                                                            if (paginationState.currentPage > 1) {
                                                                setPaginationState({
                                                                    ...paginationState,
                                                                    currentPage: paginationState.currentPage - 1,
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <i className="fas fa-chevron-left"></i>
                                                    </a>
                                                </li>
                                                {
                                                    paginationState.pages.map((page, index) => {
                                                        return (
                                                            <li className={`page-item`} key={index}>
                                                                <a className={`page-link react-link ${paginationState.currentPage === page ? 'active' : ''}`} onClick={() => {
                                                                    setPaginationState({
                                                                        ...paginationState,
                                                                        currentPage: page,
                                                                    })
                                                                }}>
                                                                    {page}
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                                <li className={`page-item`}>
                                                    <a
                                                        className={`page-link ${paginationState.currentPage < paginationState.totalPages ? 'clickable react-link' : ''}`}
                                                        onClick={() => {
                                                            if (paginationState.currentPage < paginationState.totalPages) {
                                                                setPaginationState({
                                                                    ...paginationState,
                                                                    currentPage: paginationState.currentPage + 1,
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <i className="fas fa-chevron-right"></i>
                                                    </a>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </>
                }
            </div>
        </main>
    )
}
