import React, { useState, useEffect } from 'react'
import sessionUtils from '../../utilities/SessionHandler'

import Navbar from '../../components/Private/Navbar'
import StatisticCard from '../../components/Private/Card/DashboardStatistics'
import NavigationCard from '../../components/Private/Card/DashboardNavigation'

import contestService from '../../services/contests'
import userService from '../../services/users'
import roleService from '../../services/roles'
import applicationService from '../../services/applications'
import notificationService from '../../services/notifications'
import stateService from '../../services/states'

import * as XLSX from 'xlsx'
import ExcelJS from 'exceljs';

import { useNavigate } from 'react-router-dom'

export default function Home() {

  const navigateTo = useNavigate()
  const [selectedContest, setSelectedContest] = useState(null)
  const contestRef = React.useRef(null);
  const [contests, setContests] = useState([])
  const [user, setUser] = useState(null)
  const [userRole, setUserRole] = useState(null)
  const [applications, setApplications] = useState(
    {
      por_revisar: 0,
      pre_seleccionadas: 0,
      finalizadas: 0,
      destacadas: 0
    }
  )
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [showModalCallback, setShowModalCallback] = useState(false)
  const [modalTitleCallback, setModalTitleCallback] = useState('')
  const [loading, setLoading] = useState(null)

  let credentials = sessionUtils.getParsedCredentials()

  const handleContestsLoad = async () => {
    const result = await contestService.list()
    let contestItems;

    //order the results by item.id, invert it, and then set it to the contests state

    contestItems = Object.entries(result).map(([key, value]) => value)
    contestItems = contestItems.sort((a, b) => a.id - b.id).reverse()


    setContests(contestItems)
  }

  const handleUserLoad = async () => {
    const result = await userService.get(credentials.id)
    setUser(result)
  }

  const handleRoleLoad = async () => {
    const result = await roleService.get(credentials.role_id)
    setUserRole(result)
  }

  const handleApplicationsLoad = async () => {

    const result = await applicationService.byContest(sessionStorage.getItem('selectedContest'))

    let por_revisar = 0
    let pre_seleccionadas = 0
    let finalizadas = 0
    let destacadas = 0

    const XResult = Object.entries(result).map(([key, value]) => value)

    XResult.forEach((application) => {
      if (application.applicationStatusAfterDraft === 'En revisión') {
        por_revisar++
      }
      else
        if (application.applicationStatusPreselection === 'En revisión') {
          pre_seleccionadas++
        }
        else
          if (application.applicationStatusFinalist === 'En revisión') {
            finalizadas++
          }
          else
            if (application.applicationStatusFinal === 'Destacada') {
              destacadas++
            }
    })

    setApplications({
      por_revisar,
      pre_seleccionadas,
      finalizadas,
      destacadas
    })

    setLoading(false)

  }

  const handleModal = (title) => {
    setModalTitle(title)
    setShowModal(true)
  }

  const handleModalCallback = (title) => {
    setModalTitleCallback(title)
    setShowModalCallback(true)
  }

  const handleSentCeremonyNotification = async () => {
    const result = await notificationService.ceremonyNotification()
    if (result) {
      handleModalCallback('Invitación ceremonia')
    }
  }

  const handleContestChange = () => {
    console.log("on change:" + contestRef.current.value)
    setSelectedContest(contestRef.current.value)
    sessionStorage.setItem('selectedContest', contestRef.current.value)
    setLoading(true)
    handleApplicationsLoad()
  }

  useEffect(() => {
    handleContestsLoad()
    handleUserLoad()
    handleRoleLoad()
  }, [])

  useEffect(() => {
    if (contests && contests.length > 0) {
      if (selectedContest === null) {
        if (sessionStorage.getItem('selectedContest') === null) {
          setSelectedContest(contests[0].id)
          sessionStorage.setItem('selectedContest', contests[0].id)
        } else {
          setSelectedContest(sessionStorage.getItem('selectedContest'))
        }
      }
      if (contestRef.current !== null) {
        contestRef.current.value = sessionStorage.getItem('selectedContest')
      }
      setLoading(true)
      handleApplicationsLoad()
    }
  }, [contests])

  const [XLSXgenerating, setXLSXgenerating] = useState(false)

  const createContestXLSX = async () => {
    setXLSXgenerating(true);
    const preparedStates = await stateService.list();
    const preparedContestData = contests.find(contest => contest.id === selectedContest);
    const unpreparedApplications = await applicationService.byContest(sessionStorage.getItem('selectedContest'));

    const yearOfContest = preparedContestData.created_at.date.substring(0, 4);

    const preparedApplications = unpreparedApplications.map((application) => {
      return {
        'Región': preparedStates.find(state => state.id === application.company.state_id).stateName,
        'Empresa': application.company.companyName,
        'RUT Empresa': application.company.companyDNI,
        'Nombre Empleado': application.companyUser.companyUserFullName,
        'RUT Empleado': application.companyUser.companyUserDNI,
        'Rol Empleado': application.companyUser.businessRole_id === "1" ? 'Administrador' : 'Agente',
        'Email Empleado': application.companyUser.companyUserEmail,
        'Teléfono Empleado': application.companyUser.companyUserPhone,
        'Título Postulación': application.applicationTitle,
        'Status Postulación (Borrador)': application.applicationStatusDraft,
        'Status Postulación (Postulado)': application.applicationStatusAfterDraft,
        'Status Postulación (Preselección)': application.applicationStatusPreselection,
        'Status Postulación (Finalista)': application.applicationStatusFinalist,
        'Status Postulación (Final)': application.applicationStatusFinal,
        'Fecha Postulación': application.created_at.date,
      };
    });

    const fileName = 'postulaciones_' + yearOfContest + '.xlsx';

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(preparedApplications);
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Postulaciones ' + yearOfContest);

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);

    setXLSXgenerating(false);
  }

  return (
    <>
      <Navbar />
      <main className="theme-light">
        <div className="container">
          {
            contests && contests.length > 0 && (
              <div className="row mb-4">
                <div className="col-12 col-lg-4">
                  <select className="form-select" aria-label="contestFilter" ref={contestRef} onChange={handleContestChange}>
                    {
                      contests.map((contest, key) => {
                        let fullDate = contest.created_at; //format: YYYY-MM-DD HH:MM:SS
                        //extract Year using React or JavaScript
                        let year = fullDate.date.substring(0, 4);
                        return (
                          <option key={key} value={contest.id}>{contest.contestName} ({year})</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
            )
          }
        </div>
        <div className="container">
          {
            userRole && [1, 2, 3].includes(parseInt(userRole.id)) && (
              <>
                <div className="row row-cols-lg-4 row-cols-2 g-3 mb-lg-5 mb-4 text-center">
                  <div className="col">
                    <a
                      className='react-link text-decoration-none'
                      onClick={
                        () => {
                          navigateTo('/applications/enviadas')
                        }
                      }
                    >
                      <StatisticCard
                        classType='status-send'
                        title={applications.por_revisar}
                        text='Revisar'
                        loading={loading}
                      />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className='react-link text-decoration-none'
                      onClick={
                        () => {
                          navigateTo('/applications/preseleccion')
                        }
                      }
                    >
                      <StatisticCard
                        classType='status-draft'
                        title={applications.pre_seleccionadas}
                        text='Pre-seleccionadas'
                        loading={loading}
                      />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className='react-link text-decoration-none'
                      onClick={
                        () => {
                          navigateTo('/applications/finalistas')
                        }
                      }
                    >
                      <StatisticCard
                        classType='status-approved'
                        title={applications.finalizadas}
                        text='Finalizadas'
                        loading={loading}
                      />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className='react-link text-decoration-none'
                      onClick={
                        () => {
                          navigateTo('/applications/destacadas')
                        }
                      }
                    >
                      <StatisticCard
                        classType='status-featured'
                        title={applications.destacadas}
                        text='Destacadas'
                        loading={loading}
                      />
                    </a>
                  </div>
                </div>
                <div className="row row-cols-lg-3 row-cols-1 g-4 mb-5">
                  {
                    ![3].includes(parseInt(userRole.id)) && (
                      <div className="col">
                        <NavigationCard
                          icon={<i className="bi bi-people-fill card-icon"></i>}
                          title='Administrador de Usuarios de sistema'
                          route='/users'
                        />
                      </div>
                    )
                  }
                  <div className="col">
                    <NavigationCard
                      icon={<i className="bi bi-buildings card-icon"></i>}
                      title='Administrador de Empresas'
                      route='/companies'
                    />
                  </div>
                  {
                    ![3].includes(parseInt(userRole.id)) && (
                      <div className="col">
                        <NavigationCard
                          icon={<i className="bi bi-diagram-3-fill card-icon"></i>}
                          title='Administrador de Concursos'
                          route='/contests'
                        />
                      </div>
                    )
                  }
                  <div className="col">
                    <NavigationCard
                      icon={<i className="bi bi-folder-fill card-icon"></i>}
                      title='Postulaciones'
                      route='/applications/enviadas'
                    />
                  </div>
                  {
                    <div className="col">
                      <NavigationCard
                        icon={<i className="bi bi-ticket-detailed card-icon"></i>}
                        title='Tickets'
                        route='/tickets'
                      />
                    </div>
                  }
                  {
                    <div className="col">
                      <NavigationCard
                        icon={<i className="bi bi-bell card-icon"></i>}
                        title='Notificaciones'
                        route='/configurations'
                      />
                    </div>
                  }
                  {
                    <div className="col">
                      <NavigationCard
                        icon={<i className="bi bi-trophy card-icon"></i>}
                        title='Generar Sábana de Datos'
                        callback={
                          () => createContestXLSX()
                        }
                        isCallback
                        loading={XLSXgenerating}
                      />
                    </div>
                  }
                  {/*
                    <div className="col">
                      <ModalComponent
                        id='alertModal'
                        title={modalTitle}
                        show={showModal}
                        setShow={setShowModal}
                      >
                        <p className='text-dark'>
                          ¿Está seguro que desea enviar la invitación a la ceremonia?
                        </p>
                        <div className='d-flex justify-content-end mt-4'>
                          <button
                            type='button'
                            className='btn btn-secondary me-2'
                            onClick={() => {
                              setShowModal(false)
                            }}
                          >
                            No
                          </button>
                          <button
                            type='button'
                            className='btn btn-primary'
                            onClick={() => {
                              setShowModal(false)
                              handleSentCeremonyNotification()
                            }}
                          >
                            Sí
                          </button>
                        </div>
                      </ModalComponent>
                      <ModalComponent
                        id='alertModal2'
                        title={modalTitleCallback}
                        show={showModalCallback}
                        setShow={setShowModalCallback}
                      >
                        <p className='text-dark'>
                          Invitación enviada correctamente
                        </p>
                        <div className='d-flex justify-content-end mt-4'>
                          <button
                            type='button'
                            className='btn btn-secondary'
                            onClick={() => {
                              setShowModalCallback(false)
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </ModalComponent>
                      <div className="card rounded no-shadow py-3">
                        <div className="card-body">
                          <i className="bi bi-award card-icon"></i>
                          <h4 className="h5 fw-medium my-4">
                            Invitación ceremonia
                          </h4>
                          <button className="btn btn-primary px-3 py-2 mb-3" type="button" onClick={
                            () => handleModal('Invitación ceremonia')
                          }>
                            Enviar
                          </button>
                        </div>
                      </div>
                    </div>
                        */}
                </div>
              </>
            )
          }
        </div>
      </main>
    </>
  )
}
