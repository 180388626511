import {
    createBrowserRouter,
    useLocation
} from 'react-router-dom';
import { useLayoutEffect } from 'react';

import App from '../App';
import NotFound from '../pages/NotFound';

import PublicHome from '../pages/Public/Home';
import PublicCompetitionRules from '../pages/Public/CompetitionRules';
import PublicFaq from '../pages/Public/FAQ';
import PublicContact from '../pages/Public/ContactUs';
import PublicGoodPractices from '../pages/Public/GoodPractices';
import PublicGoodPracticesGuidelines from '../pages/Public/GoodPracticesGuidelines';
import PublicGoodPracticesSearch from '../pages/Public/GoodPracticesSearch';
import PublicGoodPracticesVideoGallery from '../pages/Public/GoodPracticesVideoGallery';
import PublicGoodPracticesPhotoGallery from '../pages/Public/GoodPracticesPhotoGallery';
import PublicGoodPracticesPrint from '../pages/Public/GoodPractices/Print';

import AdministrationSignIn from '../pages/Administration/SignIn';
import AdministrationMyProfile from '../pages/Administration/MyProfile';
import AdministrationPasswordRequest from '../pages/Administration/PasswordRequest';
import AdministrationPasswordSetNew from '../pages/Administration/PasswordSetNew';
import AdministrationHome from '../pages/Administration/Home';
import AdministrationUsers from '../pages/Administration/User/List';
import AdministrationContests from '../pages/Administration/Contest/List';
import AdministrationContestFiles from '../pages/Administration/ContestFile/List';
import AdministrationCategoryGroups from '../pages/Administration/CategoryGroup/List';
import AdministrationCategories from '../pages/Administration/Category/List';
import AdministrationTags from '../pages/Administration/Tag/List';
import AdministrationTickets from '../pages/Administration/Ticket/List';
import AdministrationTicketCreate from '../pages/Administration/Ticket/Create';
import AdministrationTicketEdit from '../pages/Administration/Ticket/Edit';
import AdministrationCompanies from '../pages/Administration/Company/List';
import AdministrationCompanyUsers from '../pages/Administration/CompanyUser/List';
import AdministrationApplications from '../pages/Administration/Application/List';
import AdministrationApplicationCreate from '../pages/Administration/Application/Create';
import AdministrationApplicationEdit from '../pages/Administration/Application/Edit';
import AdministrationApplicationView from '../pages/Administration/Application/View';
import AdministrationApplicationPrint from '../pages/Administration/Application/Print';
import AdministrationConfigurations from '../pages/Administration/Configuration/List';

import CompanySignOn from '../pages/Company/SignOn';
import CompanySignUp from '../pages/Company/SignUp';
import CompanySignIn from '../pages/Company/SignIn';
import CompanyMyProfile from '../pages/Company/MyProfile';
import CompanyPasswordRequest from '../pages/Company/PasswordRequest';
import CompanyPasswordSetNew from '../pages/Company/PasswordSetNew';
import CompanyHome from '../pages/Company/Home';
import CompanyCompanyUsers from '../pages/Company/CompanyUser/List';
import CompanyApplications from '../pages/Company/Application/List';
import CompanyApplicationCreate from '../pages/Company/Application/Create';
import CompanyApplicationEdit from '../pages/Company/Application/Edit';
import CompanyApplicationView from '../pages/Company/Application/View';
import CompanyTickets from '../pages/Company/Ticket/List';
import CompanyTicketCreate from '../pages/Company/Ticket/Create';
import CompanyTicketEdit from '../pages/Company/Ticket/Edit';
import CompanyTicketSend from '../pages/Company/Ticket/Send';

const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        //document.documentElement.scrollTo(0, 0);
        document.documentElement.scrollTo({ top: 50, left: 0, behavior: "instant" });
    }, [location.pathname]);
    return children
}

const getBuildRoute = () => {

    let commonRoutes, profileRoutes, buildTargetRoutes = [];

    if (process.env.REACT_APP_BUILD_TARGET === 'public') {
        commonRoutes = [
            {
                path: '/',
                element: <App />
            },
            {
                path: '/home',
                element: <Wrapper>
                    <PublicHome />
                </Wrapper>
            },
            {
                path: '/competition-rules',
                element: <Wrapper>
                    <PublicCompetitionRules />
                </Wrapper>
            },
            {
                path: '/good-practices',
                element: <Wrapper>
                    <PublicGoodPractices />
                </Wrapper>
            },
            {
                path: '/good-practices/guidelines',
                element: <Wrapper>
                    <PublicGoodPracticesGuidelines />
                </Wrapper>
            },
            {
                path: '/good-practices/search',
                element: <Wrapper>
                    <PublicGoodPracticesSearch />
                </Wrapper>
            },
            {
                path: '/good-practices/video-gallery',
                element: <Wrapper>
                    <PublicGoodPracticesVideoGallery />
                </Wrapper>
            },
            {
                path: '/good-practices/photo-gallery/2023',
                element: <Wrapper>
                    <PublicGoodPracticesPhotoGallery year={2023} />
                </Wrapper>
            },
            {
                path: '/good-practices/photo-gallery/2024',
                element: <Wrapper>
                    <PublicGoodPracticesPhotoGallery year={2024} />
                </Wrapper>
            },
            {
                path: '/good-practices/print/:application_id',
                element: <Wrapper>
                    <PublicGoodPracticesPrint />
                </Wrapper>
            },
            {
                path: '/faq',
                element: <Wrapper>
                    <PublicFaq />
                </Wrapper>
            },
            {
                path: '/contact-us',
                element: <Wrapper>
                    <PublicContact />
                </Wrapper>
            }
        ];

        buildTargetRoutes = [
            ...commonRoutes,
        ];
    }

    if (process.env.REACT_APP_BUILD_TARGET === 'administration') {
        commonRoutes = [
            {
                path: '/',
                element: <App />
            },
            {
                path: '/sign-in',
                element: <AdministrationSignIn />
            },
            {
                path: '/home',
                element: <AdministrationHome />
            },
            {
                path: '/my-profile',
                element: <AdministrationMyProfile />
            },
            {
                path: '/password-request',
                element: <AdministrationPasswordRequest />
            },
            {
                path: '/set-new-password/:user_id/:token',
                element: <AdministrationPasswordSetNew />
            }
        ];

        profileRoutes = [
            {
                path: '/users',
                element: <AdministrationUsers />
            },
            {
                path: '/contests',
                element: <AdministrationContests />
            },
            {
                path: '/contestfiles/:contest_id',
                element: <AdministrationContestFiles />
            },
            {
                path: '/categorygroups/:contest_id',
                element: <AdministrationCategoryGroups />
            },
            {
                path: '/categories/:categoryGroup_id',
                element: <AdministrationCategories />
            },
            {
                path: '/tags/:category_id',
                element: <AdministrationTags />
            },
            {
                path: '/tickets',
                element: <AdministrationTickets />
            },
            {
                path: '/companies',
                element: <AdministrationCompanies />
            },
            {
                path: '/companyusers/:company_id',
                element: <AdministrationCompanyUsers />
            },
            {
                path: '/applications/:application_status',
                element: <AdministrationApplications />
            },
            {
                path: '/applications/:application_status/:company_id',
                element: <AdministrationApplications />
            },
            {
                path: '/application/create',
                element: <AdministrationApplicationCreate />
            },
            {
                path: '/application/edit/:application_id',
                element: <AdministrationApplicationEdit />
            },
            {
                path: '/application/view/:application_id',
                element: <AdministrationApplicationView />
            },
            {
                path: '/application/print/:application_id',
                element: <AdministrationApplicationPrint />
            },
            {
                path: '/tickets',
                element: <AdministrationTickets />
            },
            {
                path: '/ticket/create',
                element: <AdministrationTicketCreate />
            },
            {
                path: '/ticket/edit/:ticket_id',
                element: <AdministrationTicketEdit />
            },
            {
                path: '/configurations',
                element: <AdministrationConfigurations />
            }
        ];

        buildTargetRoutes = [
            ...commonRoutes,
            ...profileRoutes
        ];
    }

    if (process.env.REACT_APP_BUILD_TARGET === 'company') {
        commonRoutes = [
            {
                path: '/',
                element: <App />
            },
            {
                path: '/sign-on',
                element: <CompanySignOn />
            },
            {
                path: '/sign-up',
                element: <CompanySignUp />
            },
            {
                path: '/sign-in',
                element: <CompanySignIn />
            },
            {
                path: '/home',
                element: <CompanyHome />
            },
            {
                path: '/my-profile',
                element: <CompanyMyProfile />
            },
            {
                path: '/password-request',
                element: <CompanyPasswordRequest />
            },
            {
                path: '/set-new-password/:company_user_id/:token',
                element: <CompanyPasswordSetNew />
            }
        ];

        profileRoutes = [
            {
                path: '/companyusers',
                element: <CompanyCompanyUsers />
            },
            {
                path: '/applications',
                element: <CompanyApplications />
            },
            {
                path: '/application/create',
                element: <CompanyApplicationCreate />
            },
            {
                path: '/application/edit/:application_id',
                element: <CompanyApplicationEdit />
            },
            {
                path: '/application/view/:application_id',
                element: <CompanyApplicationView />
            },
            {
                path: '/tickets',
                element: <CompanyTicketSend />
            }
            /*
            {
                path: '/tickets',
                element: <CompanyTickets />
            },
            {
                path: '/ticket/create',
                element: <CompanyTicketCreate />
            },
            {
                path: '/ticket/edit/:ticket_id',
                element: <CompanyTicketEdit />
            }
            */
        ];

        buildTargetRoutes = [
            ...commonRoutes,
            ...profileRoutes
        ];
    }

    let specialRoutes = [
        {
            path: '*',
            element: <NotFound />
        }
    ];

    return [
        ...buildTargetRoutes,
        ...specialRoutes
    ]

}

if (process.env.REACT_APP_BUILD_TARGET === 'public') {
    require('../assets/css/landing.css');
} else {
    require('../assets/css/style.css');
}

export const Router = createBrowserRouter(
    getBuildRoute(),
    {
        basename: '/'
    }
);