import React, { useEffect, useRef } from 'react'
import * as bootstrap from 'bootstrap';
export default function ModalComponent(props) {

    const {
        id,
        title,
        show,
        setShow,
        children,
        headerClass = 'bg-light text-dark',
        callbackOnHide,
        callbackData,
        modalSize = 'modal-dialog-scrollable'
    } = props

    const modalRef = useRef(null)

    const showModal = () => {
        const modalElement = modalRef.current
        modalElement.removeAttribute('inert')
        const modal = new bootstrap.Modal(modalElement, {
            keyboard: false,
            backdrop: 'static',
        })
        modal.show()
    }

    const hideModal = () => {
        const modalElement = modalRef.current
        modalElement.setAttribute('inert', '')
        const modal = bootstrap.Modal.getInstance(modalElement)
        modal.hide()
        if (!show && callbackOnHide && callbackData != null) {
            callbackOnHide(callbackData)
        }

    }

    useEffect(() => {
        
        const modalElement = modalRef.current
        
        const modal = new bootstrap.Modal(modalElement, {
            keyboard: false,
            backdrop: 'static',
        })

        return () => {
            modal.dispose()
        }

    }, [])

    useEffect(() => {
        if (show) {
            showModal()
        } else {
            hideModal()
        }
    }, [show])

    return (
        <>
            <div ref={modalRef} id={id} className="modal fade border border-white" tabIndex="-1" aria-labelledby="exampleModalLabel">
                <div className={`modal-dialog modal-dialog-centered ` + modalSize}>
                    <div className="modal-content">
                        <div className={`modal-header ` + headerClass}>
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => setShow(false)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    
}