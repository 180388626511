import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../../components/Private/Navbar'
import ListSection from '../../../components/Private/ListSection'
import ModalComponent from '../../../components/Private/ModalComponent'

import contestService from '../../../services/contests'
import applicationService from '../../../services/applications'
import sessionUtils from '../../../utilities/SessionHandler'

import * as XLSX from 'xlsx'

export default function List() {

  const navigateTo = useNavigate()

  const [selectedContest, setSelectedContest] = useState(null)
  const [contests, setContests] = useState([])

  const {
    application_status,
    company_id
  } = useParams()

  const credentials = sessionUtils.getParsedCredentials()

  const handleContestsLoad = async () => {
    const result = await contestService.list()
    let contestItems;

    //order the results by item.id, invert it, and then set it to the contests state

    contestItems = Object.entries(result).map(([key, value]) => value)
    contestItems = contestItems.sort((a, b) => a.id - b.id).reverse()


    setContests(contestItems)
  }

  const [currentStatus, setCurrentStatus] = useState(application_status)

  let localUrl;
  if (window.location.port === 80) {
    localUrl = window.location.protocol + '//' + window.location.hostname
  } else {
    localUrl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port
  }

  const [statusList, setStatusList] = useState([
    {
      title: 'Enviadas',
      value: 'enviadas'
    },
    {
      title: 'Pre-Selección',
      value: 'preseleccion'
    },
    {
      title: 'Finalistas',
      value: 'finalistas'
    },
    {
      title: 'Destacadas',
      value: 'destacadas'
    }
  ])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalTitle, setDeleteModalTitle] = useState('Borrar postulación')

  const [contestData, setContestData] = useState(null)
  const [contestDataPerStage, setContestDataPerStage] = useState({})
  const [listData, setListData] = useState(null)

  const [selectedItemId, setSelectedItemId] = useState(null)

  const handleDataDelete = async (itemId) => {
    await applicationService.remove(itemId)
    await handleDataLoad()
  }

  const handleDataLoad = async () => {

    let filters = {}
    switch (application_status) {
      case 'enviadas':
        filters = {
          applicationStatusDraft: 'Enviada' + '|' + 'Devuelta',
          applicationStatusAfterDraft: 'En revisión' + '|' + 'Rechazada'
        }
        break;
      case 'preseleccion':
        filters = {
          applicationStatusDraft: 'Enviada',
          applicationStatusAfterDraft: 'Aprobada',
          applicationStatusPreselection: 'En revisión' + '|' + 'No seleccionada'
        }
        break;
      case 'finalistas':
        filters = {
          applicationStatusDraft: 'Enviada',
          applicationStatusAfterDraft: 'Aprobada',
          applicationStatusPreselection: 'Seleccionada',
          applicationStatusFinalist: 'En revisión' + '|' + 'No seleccionada'
        }
        break;
      case 'destacadas':
        filters = {
          applicationStatusDraft: 'Enviada',
          applicationStatusAfterDraft: 'Aprobada',
          applicationStatusPreselection: 'Seleccionada',
          applicationStatusFinalist: 'Seleccionada',
          applicationStatusFinal: 'No destacada' + '|' + 'Destacada' + '|' + 'Ganadora'
        }
        break;
    }

    if (selectedContest) {
      filters = {
        ...filters,
        contest_id: selectedContest
      }
    }

    const result = await applicationService.search(filters)

    let filteredResult = result;

    if (parseInt(credentials.role_id) === 3) {
      filteredResult = filteredResult.filter(
        item => {
          let showApplication = false;
          if (item.user_id === null) {
            showApplication = true;
          }
          if (item.user_id === credentials.id) {
            showApplication = true;
          }
          return showApplication;
        }
      )
    }

    let stages = [
      'borrador',
      'enviadas',
      'preseleccion',
      'finalistas',
      'destacadas',
    ];

    let filteredPerStage;
    let filteredPerStageResult = {};
    stages.map((stage, index) => {
      filteredPerStage = result;

      switch (stage) {
        case 'borrador':
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusDraft === 'Borrador'
          )
          break;
        case 'enviadas':
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusDraft === 'Enviada' || item.applicationStatusDraft === 'Devuelta'
          )
          filteredPerStage = filteredPerStage.filter(
            item => {
              return item.applicationStatusAfterDraft === 'En revisión' || item.applicationStatusAfterDraft === 'Rechazada'
            }
          )
          break;
        case 'preseleccion':
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusDraft === 'Enviada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusAfterDraft === 'Aprobada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusPreselection === 'En revisión'
          )
          break;
        case 'finalistas':
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusDraft === 'Enviada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusAfterDraft === 'Aprobada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusPreselection === 'Seleccionada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusFinalist === 'En revisión'
          )
          break;
        case 'destacadas':
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusDraft === 'Enviada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusAfterDraft === 'Aprobada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusPreselection === 'Seleccionada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusFinalist === 'Seleccionada'
          )
          break;
        default:
          filteredPerStage = [];
      }

      filteredPerStageResult[stage] = filteredPerStage;

    })

    setContestDataPerStage(filteredPerStageResult)

    switch (currentStatus) {
      case 'enviadas':
        filteredResult = filteredResult.filter(
          item => item.applicationStatusDraft === 'Enviada' || item.applicationStatusDraft === 'Devuelta'
        )
        filteredResult = filteredResult.filter(
          item => {
            return item.applicationStatusAfterDraft === 'En revisión' || item.applicationStatusAfterDraft === 'Rechazada'
          }
        )
        break;
      case 'preseleccion':
        filteredResult = filteredResult.filter(
          item => item.applicationStatusDraft === 'Enviada'
        )
        filteredResult = filteredResult.filter(
          item => item.applicationStatusAfterDraft === 'Aprobada'
        )
        filteredResult = filteredResult.filter(
          item => item.applicationStatusPreselection === 'En revisión' || item.applicationStatusPreselection === 'No seleccionada'
        )
        break;
      case 'finalistas':
        filteredResult = filteredResult.filter(
          item => item.applicationStatusDraft === 'Enviada'
        )
        filteredResult = filteredResult.filter(
          item => item.applicationStatusAfterDraft === 'Aprobada'
        )
        filteredResult = filteredResult.filter(
          item => item.applicationStatusPreselection === 'Seleccionada'
        )
        filteredResult = filteredResult.filter(
          item => item.applicationStatusFinalist === 'En revisión' || item.applicationStatusFinalist === 'No seleccionada'
        )
        break;
      case 'destacadas':
        filteredResult = filteredResult.filter(
          item => item.applicationStatusDraft === 'Enviada'
        )
        filteredResult = filteredResult.filter(
          item => item.applicationStatusAfterDraft === 'Aprobada'
        )
        filteredResult = filteredResult.filter(
          item => item.applicationStatusPreselection === 'Seleccionada'
        )
        filteredResult = filteredResult.filter(
          item => item.applicationStatusFinalist === 'Seleccionada'
        )
        break;
      default:
        filteredResult = [];
        break;
    }

    if (company_id) {
      filteredResult = filteredResult.filter(
        item => {
          return parseInt(item.company.id) === parseInt(company_id)
        }
      )
    }

    setListData(filteredResult)
  }

  const handleContestLoad = async () => {
    try {
      //const result = await contestService.showActive()
      const result = await contestService.get(selectedContest)
      setContestData(
        {
          ...result,
          contestYear: new Date(result.contestClosingDateTime).getFullYear()
        }
      )
    } catch (error) {
      setContestData(false)
    }

  }

  useEffect(() => {
    handleContestsLoad()
  }, [])

  useEffect(() => {
    if (contests && contests.length > 0 && selectedContest) {
      handleContestLoad()
    }
  }, [contests, selectedContest])

  useEffect(() => {
    setCurrentStatus(application_status)
  }, [contestData])

  useEffect(() => {
    handleDataLoad()
  }, [currentStatus, selectedContest])

  const handleDownloadListAsExcel = () => {

    // colocar columna fecha de última vez que se editó, y enlace

    const dataBorrador = contestDataPerStage.borrador.map((item, index) => {
      return {
        'Título de la Postulación': item.applicationTitle,
        'Categoría': item.category?.categoryName || "-",
        'Empresa': item.company?.companyName,
        'Agente': item.user?.userFullName || "No asignado",
        'Enviado': item.applicationStatusEditedByCompanyDateTime,
        'Estado': item.applicationStatusDraft,
        'Enlace': localUrl + '/application/print/' + item.id,
      }
    })

    const dataEnviadas = contestDataPerStage.enviadas.map((item, index) => {
      return {
        'Título de la Postulación': item.applicationTitle,
        'Categoría': item.category?.categoryName || "-",
        'Empresa': item.company?.companyName,
        'Agente': item.user?.userFullName || "No asignado",
        'Enviado': item.applicationStatusEditedByCompanyDateTime,
        'Estado': item.applicationStatusAfterDraft,
        'Enlace': localUrl + '/application/print/' + item.id,
      }
    })

    const dataPreseleccion = contestDataPerStage.preseleccion.map((item, index) => {
      return {
        'Título de la Postulación': item.applicationTitle,
        'Categoría': item.category?.categoryName || "-",
        'Empresa': item.company?.companyName,
        'Agente': item.user?.userFullName || "No asignado",
        'Enviado': item.applicationStatusEditedByCompanyDateTime,
        'Estado': item.applicationStatusPreselection,
        'Enlace': localUrl + '/application/print/' + item.id,
      }
    })

    const dataFinalistas = contestDataPerStage.finalistas.map((item, index) => {
      return {
        'Título de la Postulación': item.applicationTitle,
        'Categoría': item.category?.categoryName || "-",
        'Empresa': item.company?.companyName,
        'Agente': item.user?.userFullName || "No asignado",
        'Enviado': item.applicationStatusEditedByCompanyDateTime,
        'Estado': item.applicationStatusFinalist,
        'Enlace': localUrl + '/application/print/' + item.id,
      }
    })

    const dataDestacadas = contestDataPerStage.destacadas.map((item, index) => {
      return {
        'Título de la Postulación': item.applicationTitle,
        'Categoría': item.category?.categoryName || "-",
        'Empresa': item.company?.companyName,
        'Agente': item.user?.userFullName || "No asignado",
        'Enviado': item.applicationStatusEditedByCompanyDateTime,
        'Estado': item.applicationStatusFinal,
        'Enlace': localUrl + '/application/print/' + item.id,
      }
    })

    //one per page

    const data = [
      {
        name: 'Borrador',
        data: dataBorrador
      },
      {
        name: 'Enviadas',
        data: dataEnviadas
      },
      {
        name: 'Pre-Selección',
        data: dataPreseleccion
      },
      {
        name: 'Finalistas',
        data: dataFinalistas
      },
      {
        name: 'Destacadas',
        data: dataDestacadas
      },
    ]

    const fileName = 'postulaciones_' + contestData.contestYear + '.xlsx'

    const workbook = XLSX.utils.book_new()
    data.map((item, index) => {
      const worksheet = XLSX.utils.json_to_sheet(item.data)
      XLSX.utils.book_append_sheet(workbook, worksheet, item.name)
    })
    XLSX.writeFile(workbook, fileName)
  }

  const handleDownloadListAsExcelAllStages = async () => {

    //create the same as handleDownloadListAsExcel, but for all stages

    const dataBorrador = await applicationService.search(
      {
        applicationStatusDraft: 'Borrador' + '|' + 'Devuelta',
      }
    );

    const dataEnviadas = await applicationService.search(
      {
        applicationStatusDraft: 'Enviada' + '|' + 'Devuelta',
        applicationStatusAfterDraft: 'En revisión' + '|' + 'Rechazada'
      }
    );

    const dataPreseleccion = await applicationService.search(
      {
        applicationStatusDraft: 'Enviada',
        applicationStatusAfterDraft: 'Aprobada',
        applicationStatusPreselection: 'En revisión' + '|' + 'No seleccionada'
      }
    );

    const dataFinalistas = await applicationService.search(
      {
        applicationStatusDraft: 'Enviada',
        applicationStatusAfterDraft: 'Aprobada',
        applicationStatusPreselection: 'Seleccionada',
        applicationStatusFinalist: 'En revisión' + '|' + 'No seleccionada'
      }
    );

    const dataDestacadas = await applicationService.search(
      {
        applicationStatusDraft: 'Enviada',
        applicationStatusAfterDraft: 'Aprobada',
        applicationStatusPreselection: 'Seleccionada',
        applicationStatusFinalist: 'Seleccionada',
        applicationStatusFinal: 'No destacada' + '|' + 'Destacada' + '|' + 'Ganadora'
      }
    );

    const data = [
      {
        name: 'Borrador',
        data: dataBorrador.map((item, index) => {
          return {
            'Título de la Postulación': item.applicationTitle,
            'Categoría': item.category?.categoryName || "-",
            'Empresa': item.company?.companyName,
            'Agente': item.user?.userFullName || "No asignado",
            'Enviado': item.applicationStatusEditedByCompanyDateTime,
            'Estado': item.applicationStatusDraft,
            'Enlace': localUrl + '/application/print/' + item.id,
          }
        })
      },
      {
        name: 'Enviadas',
        data: dataEnviadas.map((item, index) => {
          return {
            'Título de la Postulación': item.applicationTitle,
            'Categoría': item.category?.categoryName || "-",
            'Empresa': item.company?.companyName,
            'Agente': item.user?.userFullName || "No asignado",
            'Enviado': item.applicationStatusEditedByCompanyDateTime,
            'Estado': item.applicationStatusAfterDraft,
            'Enlace': localUrl + '/application/print/' + item.id,
          }
        })
      },
      {
        name: 'Pre-Selección',
        data: dataPreseleccion.map((item, index) => {
          return {
            'Título de la Postulación': item.applicationTitle,
            'Categoría': item.category?.categoryName || "-",
            'Empresa': item.company?.companyName,
            'Agente': item.user?.userFullName || "No asignado",
            'Enviado': item.applicationStatusEditedByCompanyDateTime,
            'Estado': item.applicationStatusPreselection,
            'Enlace': localUrl + '/application/print/' + item.id,
          }
        })
      },
      {
        name: 'Finalistas',
        data: dataFinalistas.map((item, index) => {
          return {
            'Título de la Postulación': item.applicationTitle,
            'Categoría': item.category?.categoryName || "-",
            'Empresa': item.company?.companyName,
            'Agente': item.user?.userFullName || "No asignado",
            'Enviado': item.applicationStatusEditedByCompanyDateTime,
            'Estado': item.applicationStatusFinalist,
            'Enlace': localUrl + '/application/print/' + item.id,
          }
        })
      },
      {
        name: 'Destacadas',
        data: dataDestacadas.map((item, index) => {
          return {
            'Título de la Postulación': item.applicationTitle,
            'Categoría': item.category?.categoryName || "-",
            'Empresa': item.company?.companyName,
            'Agente': item.user?.userFullName || "No asignado",
            'Enviado': item.applicationStatusEditedByCompanyDateTime,
            'Estado': item.applicationStatusFinal,
            'Enlace': localUrl + '/application/print/' + item.id,
          }
        }),
      }
    ]

    const fileName = 'postulaciones_' + contestData.contestYear + '.xlsx'

    const workbook = XLSX.utils.book_new()
    data.map((item, index) => {
      const worksheet = XLSX.utils.json_to_sheet(item.data)
      XLSX.utils.book_append_sheet(workbook, worksheet, item.name)
    })
    XLSX.writeFile(workbook, fileName)
  }

  const handleContestChange = () => {
    let updateDate = document.getElementById('contestId')
    setSelectedContest(updateDate.value)
    sessionStorage.setItem('selectedContest', updateDate.value)
  }

  useEffect(() => {
    if (contests && contests.length > 0 && listData) {
      if (selectedContest === null) {
        if (sessionStorage.getItem('selectedContest') === null) {
          setSelectedContest(contests[0].id)
          sessionStorage.setItem('selectedContest', contests[0].id)
        } else {
          setSelectedContest(sessionStorage.getItem('selectedContest'))
        }
      }
      let updateDate = document.getElementById('contestId')
      //updateDate is a select. We need to set the value of the select to the selectedContest
      updateDate.value = sessionStorage.getItem('selectedContest')
    }
  }, [contests, listData])

  return (
    <>
      <Navbar />
      {
        listData && <ListSection
          aboveTitle={
            <div className="container">
              {
                contests && contests.length > 0 && (
                  <div className="row mb-4">
                    <div className="col-12 col-lg-4">
                      <select className="form-select" aria-label="contestFilter" id='contestId' onChange={handleContestChange}>
                        {
                          contests.map((contest, key) => {
                            let fullDate = contest.created_at; //format: YYYY-MM-DD HH:MM:SS
                            //extract Year using React or JavaScript
                            let year = fullDate.date.substring(0, 4);
                            return (
                              <option key={key} value={contest.id}>{contest.contestName} ({year})</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                )
              }
            </div>
          }
          belowTitle={
            <div className="container">
              <div className="row mt-4">
                <div className="col-12">
                  {
                    statusList && statusList.map((item, index) => {
                      return (
                        <button
                          key={index}
                          className={`btn ${application_status === item.value ? 'btn-primary' : 'btn-outline-primary'} m-1`}
                          onClick={() => {
                            if (!company_id) {
                              setCurrentStatus(item.value)
                              //navigateTo('/applications/' + item.value)
                              window.location.href = '/applications/' + item.value
                            } else {
                              setCurrentStatus(item.value)
                              //navigateTo('/applications/' + item.value + '/' + company_id)
                              window.location.href = '/applications/' + item.value + '/' + company_id
                            }
                          }}
                        >
                          {item.title}
                        </button>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          }
          title={`Postulaciones ${contestData && contestData.contestYear}`}
          hasSearch={true}
          hasYear={false}
          tabs={false}
          searchText={`Buscar en esta etapa`}
          pagination={
            {
              itemsPerPage: 10,
              maxPagesInPagination: 5,
              currentPage: 1,
              prevPage: 'Anterior',
              nextPage: 'Siguiente',
              lastPage: 'Última',
            }
          }
          backRoute={
            {
              title: 'Volver',
              onClick: () => {
                if (!company_id) {
                  navigateTo('/home')
                } else {
                  navigateTo('/companies')
                }
              }
            }
          }
          actions={
            [
              {
                title: 'Descargar etapa actual (' + currentStatus + ')',
                onClick: () => {
                  handleDownloadListAsExcel()
                },
                className: 'btn-success'
              },
              {
                title: 'Descargar todas las etapas',
                onClick: () => {
                  handleDownloadListAsExcelAllStages()
                },
                className: 'btn-outline-success'
              }
            ]
          }
          columns={
            [
              {
                title: 'Título de la Postulación',
                field: 'applicationTitle',
                type: 'text',
              },
              {
                title: 'Categoría',
                field: 'category_id',
                type: 'text',
              },
              {
                title: 'Empresa',
                field: 'companyName',
                type: 'text',
              },
              {
                title: 'Agente',
                field: 'userName',
                type: 'text',
              },
              {
                title: 'Enviado',
                field: 'applicationStatusEditedByCompanyFirstDateTime',
                type: 'date',
              },
              {
                title: 'Estado',
                field: 'applicationStatus',
                type: 'text',
              },
              // colocar botón en columna acción para descargar PDF
              {
                title: 'Acciones',
                field: 'actions',
                type: 'actions',
              },
            ]
          }
          rows={
            [
              ...listData.map((item, index) => {

                let actions = []

                switch (currentStatus) {
                  case 'enviadas':
                    if (item.applicationStatusAfterDraft === 'En revisión') {
                      actions = [
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo('/application/view/' + item.id)
                          }
                        }
                      ]
                      actions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo('/application/edit/' + item.id)
                          }
                        }
                      )
                      if (item.applicationStatusDraft == 'Devuelta') {
                        actions.push(
                          {
                            className: 'btn',
                            icon: 'bi bi-trash',
                            tooltip: 'Borrar',
                            onClick: () => {
                              setSelectedItemId(item.id)
                              setDeleteModalTitle('Borrar ' + item.applicationTitle)
                              setShowDeleteModal(true)
                            }
                          }
                        )
                      }
                    } else if (item.applicationStatusAfterDraft === 'Rechazada') {
                      actions = [
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo('/application/view/' + item.id)
                          }
                        },
                      ]
                      actions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo('/application/edit/' + item.id)
                          }
                        }
                      )
                    }

                    break;
                  case 'preseleccion':
                    if (item.applicationStatusPreselection === 'En revisión') {
                      actions = [
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo('/application/view/' + item.id)
                          }
                        },
                      ]
                      actions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo('/application/edit/' + item.id)
                          }
                        }
                      )

                    } else if (item.applicationStatusPreselection === 'No seleccionada') {
                      actions = [
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo('/application/view/' + item.id)
                          }
                        },
                      ]
                      actions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo('/application/edit/' + item.id)
                          }
                        }
                      )
                    }
                    break;
                  case 'finalistas':
                    if (item.applicationStatusFinalist === 'En revisión') {
                      actions = [
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo('/application/view/' + item.id)
                          }
                        },
                      ]
                      actions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo('/application/edit/' + item.id)
                          }
                        }
                      )
                    } else if (item.applicationStatusFinalist === 'No seleccionada') {
                      actions = [
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo('/application/view/' + item.id)
                          }
                        },
                      ]
                      actions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo('/application/edit/' + item.id)
                          }
                        }
                      )
                    }
                    break;
                  case 'destacadas':
                    if (item.applicationStatusFinal === 'No destacada') {
                      actions = [
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo('/application/view/' + item.id)
                          }
                        },
                      ]
                      actions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo('/application/edit/' + item.id)
                          }
                        }
                      )
                    } else if (item.applicationStatusFinal === 'Destacada') {
                      actions = [
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo('/application/view/' + item.id)
                          }
                        },
                      ]
                      actions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo('/application/edit/' + item.id)
                          }
                        }
                      )
                    } else if (item.applicationStatusFinal === 'Ganadora') {
                      actions = [
                        {
                          className: 'btn',
                          icon: 'bi bi-eye',
                          tooltip: 'Revisar',
                          onClick: () => {
                            navigateTo('/application/view/' + item.id)
                          }
                        },
                      ]
                      actions.push(
                        {
                          className: 'btn',
                          icon: 'bi bi-pencil',
                          tooltip: 'Editar',
                          onClick: () => {
                            navigateTo('/application/edit/' + item.id)
                          }
                        }
                      )
                    }
                    break;
                }

                actions.push(
                  {
                    className: 'btn',
                    icon: 'bi bi-printer',
                    tooltip: 'Ver',
                    onClick: () => {
                      navigateTo('/application/print/' + item.id)
                    }
                  }
                )


                let itemStatus = '';

                switch (currentStatus) {
                  case 'enviadas':
                    if (item.applicationStatusDraft === 'Devuelta') {
                      itemStatus = {
                        text: item.applicationStatusDraft,
                        className: 'status-featured align-middle'
                      }
                    } else if (item.applicationStatusAfterDraft === 'En revisión') {
                      itemStatus = {
                        text: item.applicationStatusAfterDraft,
                        className: 'status-send align-middle'
                      }
                    } else if (item.applicationStatusAfterDraft === 'Rechazada') {
                      itemStatus = {
                        text: item.applicationStatusAfterDraft,
                        className: 'status-rejected align-middle'
                      }
                    } else if (item.applicationStatusAfterDraft === 'Aprobada') {
                      itemStatus = {
                        text: item.applicationStatusAfterDraft,
                        className: 'status-approved align-middle'
                      }
                    }
                    break;
                  case 'preseleccion':
                    if (item.applicationStatusPreselection === 'En revisión') {
                      itemStatus = {
                        text: item.applicationStatusPreselection,
                        className: 'status-send align-middle'
                      }
                    } else if (item.applicationStatusPreselection === 'No seleccionada') {
                      itemStatus = {
                        text: item.applicationStatusPreselection,
                        className: 'status-rejected align-middle'
                      }
                    } else if (item.applicationStatusPreselection === 'Seleccionada') {
                      itemStatus = {
                        text: item.applicationStatusPreselection,
                        className: 'status-approved align-middle'
                      }
                    }
                    break;
                  case 'finalistas':
                    if (item.applicationStatusFinalist === 'En revisión') {
                      itemStatus = {
                        text: item.applicationStatusFinalist,
                        className: 'status-send align-middle'
                      }
                    } else if (item.applicationStatusFinalist === 'No seleccionada') {
                      itemStatus = {
                        text: item.applicationStatusFinalist,
                        className: 'status-rejected align-middle'
                      }
                    } else if (item.applicationStatusFinalist === 'Seleccionada') {
                      itemStatus = {
                        text: item.applicationStatusFinalist,
                        className: 'status-approved align-middle'
                      }
                    }
                    break;
                  case 'destacadas':
                    if (item.applicationStatusFinal === 'No destacada') {
                      itemStatus = {
                        text: item.applicationStatusFinal,
                        className: 'status-send align-middle'
                      }
                    } else if (item.applicationStatusFinal === 'Destacada') {
                      itemStatus = {
                        text: item.applicationStatusFinal,
                        className: 'status-featured align-middle'
                      }
                    } else if (item.applicationStatusFinal === 'Ganadora') {
                      itemStatus = {
                        text: item.applicationStatusFinal,
                        className: 'status-approved align-middle'
                      }
                    }

                    break;
                }

                let appSentDate = item.applicationStatusEditedByCompanyFirstDateTime;
                if (appSentDate) {
                  appSentDate = new Date(appSentDate)
                  appSentDate = new Date(appSentDate.getTime() + appSentDate.getTimezoneOffset() * 60000)
                  appSentDate = appSentDate.toLocaleDateString('es-CL')
                } else {
                  appSentDate = '-'
                }
                appSentDate = {
                  text: appSentDate,
                  className: 'align-middle'
                }

                return {
                  applicationTitle: item.applicationTitle,
                  companyName: item.company?.companyName,
                  category_id: item.category?.categoryName || "-",
                  userName: {
                    text: item.user?.userFullName || "No asignado",
                    className: item.user?.userFullName ? "" : "text-danger align-middle"
                  },
                  applicationStatusEditedByCompanyFirstDateTime: appSentDate,
                  applicationStatus: itemStatus,
                  actions: actions,
                }
              })
            ]
          }
        />
      }
      <ModalComponent
        id='deleteModal'
        title={deleteModalTitle}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      >
        <p>¿Está seguro que desea borrar esta postulación?</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => {
              handleDataDelete(selectedItemId)
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Borrar
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
