import APIDriver from './api/APIDriver'
const api = new APIDriver('notifications')

const methods = {
    contactUs: (data) => {
        api.setBearerProtection();
        return api.post('send-notification',
            {
                ...data,
                notificationType: 'contact-us',
                notificationSubject: 'Contacto Web',
            }
        );
    },
    contactTicket: (data) => {
        api.setBearerProtection();
        return api.post('send-notification',
            {
                ...data,
                notificationType: 'ticket-us',
                notificationSubject: 'Ticket de Soporte',
            }
        );
    },
    ceremonyNotification : () => {
        api.setBearerProtection();
        return api.post('send-ceremony-notification',
            {
                notificationType: 'send-ceremony-notification',
                notificationSubject: 'Seminario de Premiación - Concurso Compartiendo Buenas Prácticas de Seguridad y Salud en el Trabajo 2023',
            }
        );
    },
}


export default methods;